.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  padding: 0;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  color: rgb(211, 210, 210);
  box-sizing: border-box;
  margin: 0;
  height: 100vh;
  font-size: 12px;
}

.light {
  background: white;
}

.roomContainer {
  height: 100vh;
}

.error {
  color: red;
}

.lk-grid-layout-wrapper {
  height: 100%;
}

.lk-focus-layout {
  height: 100%;
}
.lk-connection-quality{
  opacity: 1 !important;
}
/* things like name, connection quality, etc make less sense in a recording, hide for now */
/* .lk-participant-metadata {
  display: none;
} */